import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../components";

import locales from "../../constants";

const AmazingThailand = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Amazing Thailand",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/tourismAoT_og_image.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #df2cc6, #c523b0, #c230ad, #9200b9, #d64ac2 )",
        icons: "#9200b9",
        navClass: "tourismAoT__nav",
        ogImage: require("../../assets/img/portfolio/tourismAoT_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/amazing-thailand/",
      }}
    >
      <PortfolioHeader name="tourismAoT" height="260" />

      <section
        className="container-fluid portfolio-intro tourismAot-intro tourismAoT-section tourismAoT-section--1"
        id="info"
      >
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Amazing Thailand</h1>
              <ul>
                <li>Strona internetowa</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner inner--divider">
              <p>
                Dla Tourism Authority of Thailand przygotowaliśmy projekt strony
                internetowej mającej na celu promocję Tajlandii w Polsce.
                Stworzyliśmy kilkadziesiąt widoków przedstawiających różnorakie
                walory turystyczne jakimi może poszczycić się Tajlandia. Strona
                zawiera interaktywną mapę regionów, listę i opisy zabytków,
                plaż, atrakcji sportowych i kulturalnych i wiele innych
                kluczowych informacji, niezbędnych każdemu kto chce spędzić
                wspaniały czas w tajskim raju, samemu lub z rodziną.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid tourismAoT-section tourismAoT-section--2">
        <div className="row">
          <div className="col-12 text-center">
            <div className="scroll_wrapper scroll_wrapper--macbook">
              <div className="img_wrapper">
                <picture>
                  <source
                    srcset={require("../../assets/img/portfolio/tourismAoT_full.avif")}
                    type="image/avif"
                    alt="Strona Tourism Authority of Thailand"
                    draggable="false"
                  />
                  <img
                    src={require("../../assets/img/portfolio/tourismAoT_full.jpg")}
                    className="img-fluid"
                    alt="Strona Tourism Authority of Thailand"
                    draggable="false"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid tourismAoT-section tourismAoT-section--3">
        <div className="tourismAoT-section__rectangle"></div>
        <div className="tourismAoT-section__rectangle"></div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/tourismAoT_page_main.jpg")}
              alt="Strona główna"
            />
          </div>
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/tourismAoT_page_massage.jpg")}
              alt="Strona główna"
            />
          </div>
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/tourismAoT_page_weather.jpg")}
              alt="Strona główna"
            />
          </div>
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/tourismAoT_phone.png")}
              alt="Strona główna"
            />
          </div>
          <div className="col-12 tourismAoT-section__banner">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/tourismAoT_banner.jpg")}
              alt=""
            />
          </div>
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/tourismAoT_page_entries.jpg")}
              alt="Strona główna"
            />
          </div>
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/tourismAoT_page_tips.jpg")}
              alt="Strona główna"
            />
          </div>
          <div className="col-12 col-lg-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/tourismAoT_page_academy.jpg")}
              alt="Strona główna"
            />
          </div>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default AmazingThailand;
